"use client";
import { useEffect } from "react";
import LogRocket from "logrocket";
import "./globals.scss";

export default function Layout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      LogRocket.init("cyftmy/transacted-diligence-platform");
    }
  }, []);

  return <>{children}</>;
}
